import React from 'react';
import Styles from "./cesium2dis.module.css"
import Cityset1 from "./../citysetting/citytitle1"
import Cityset2 from "./../citysetting/citytitle2"

import Hanrei from "../../containers/hanrei"

const useStateWithLocalStorage = localStorageKey => {
  const [value, setValue] = React.useState(
    typeof window !== 'undefined' && window.localStorage.getItem(localStorageKey)
    || ''
  );
 
  React.useEffect(() => {
    localStorage.setItem(localStorageKey, value);
  }, [value]);
 
  return [value, setValue];
};

const App = (props) => {
  const [value, setValue] = useStateWithLocalStorage(
    'cityset1'
  );
  
  const onChange = event => setValue(event.target.value);
  const city1 = {value}
  const city1nfd = city1.value.normalize( 'NFD' ) ;

  const kmlsrc1 = 'https://empire-dev3.kashika.net/Cesium/Apps/kashika2/?sourceid='+props.no+'/'+ city1nfd + '_' + props.kml_file.normalize( 'NFD' );
  const kmldown1 = 'https://empire-dev3.kashika.net/kml/'+props.no+'/'+ city1nfd + '_' + props.kml_file.normalize( 'NFD' );
  // const kmlsrc1 = 'https://empire-dev3.kashika.net/Cesium/Apps/kashika/?sourceid=H26小売業販売額×H27人口総数/'+ city1.value + '_H26小売業販売額×H27人口総数.kmz';
  // const kmldown1 = 'https://empire-dev3.kashika.net/kml/H26小売業販売額×H27人口総数/'+ city1.value + '_H26小売業販売額×H27人口総数.kmz';

  const [value2, setValue2] = useStateWithLocalStorage(
    'cityset2'
  );
  const onChange2 = event => setValue2(event.target.value2);
  const city2 = {value2};
  console.log(city2);
  
  const city2nfd = city2.value2.normalize( 'NFD' ) ;
  console.log(city2nfd);
  

  const kmlsrc2 = 'https://empire-dev3.kashika.net/Cesium/Apps/kashika2/?sourceid='+props.no+'/'+ city2nfd + '_' + props.kml_file.normalize( 'NFD' );
  const kmldown2 = 'https://empire-dev3.kashika.net/kml/'+props.no+'/'+ city2nfd + '_' + props.kml_file.normalize( 'NFD' );

  // const kmlsrc2 = 'https://empire-dev3.kashika.net/Cesium/Apps/kashika/?sourceid=H26小売業販売額×H27人口総数/'+ city2.value2 + '_H26小売業販売額×H27人口総数.kmz';
  // const kmldown2 = 'https://empire-dev3.kashika.net/kml/H26小売業販売額×H27人口総数/'+ city2.value + '_H26小売業販売額×H27人口総数.kmz';
  

  return (
    <div style={{display: "flex"}}>
      <div className={Styles.panel}>
        <div>
          <Cityset2 />
        </div>
        <div className={Styles.previewheader}>プレビュー</div>

        <div className={Styles.preview}>
          <iframe
            src={kmlsrc2}
            frameBorder="0"
            allowFullScreen
            title="cesium"
            width="100%"
            height="500px"
          />
        </div>
        <div className={Styles.buttondiv}>
          <a className={Styles.button} href={kmldown2} download>KMLダウンロード</a>
        </div>
      </div>
      <div className={Styles.panel}>
        <div>
          <Cityset1 />
        </div>
        <div className={Styles.previewheader}>プレビュー</div>
        <div className={Styles.preview}>
          <iframe
            src={kmlsrc1}
            frameBorder="0"
            allowFullScreen
            title="cesium"
            width="100%"
            height="500px"
          />
        </div>
        <div className={Styles.buttondiv}>
          <a className={Styles.button} href={kmldown1} download>KMLダウンロード</a>
        </div>
      </div>

    </div>
  );
};
 
export default App;


{/*

<iframe src="https://empire-dev3.kashika.net/Cesium/Apps/kashika/?sourceid=H26小売業販売額×H27人口総数/福岡県糸島市_H26小売業販売額×H27人口総数.kmz"
  frameBorder="0"
  allowFullScreen
  title="cesium"
  width=100%
  height=400px
>
*/}